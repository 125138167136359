import Formatter from '../Formatter/Formatter';
import CarbonFlux from '../CarbonFlux/CarbonFlux';

class NFT {
  constructor({
    id,
    _id,
    is_sbt,
    name,
    blockchain = {},
    ipfs = {},
    certificate,
    image_project,
    name_project,
    asset_code,
    blockchain_hash,
    ipfs_hash,
    issuing_account_public,
    issuing_account_secret,
    offset_period,
    project,
    used_by,
    user,
    created_at,
    updated_at,
    bought_at,
    used_at,
    used,
    s3_url,
    carbonflux = [],
    publicKey,
  }) {
    this.id = id || _id;
    this.name = name;
    this.blockchain = blockchain;
    this.ipfs = ipfs;
    this.is_sbt = is_sbt;
    this.certificate = certificate;
    this.image_project = image_project;
    this.name_project = name_project;
    this.asset_code = asset_code;
    this.blockchain_hash = blockchain_hash;
    this.ipfs_hash = ipfs_hash;
    this.issuing_account_public = issuing_account_public;
    this.issuing_account_secret = issuing_account_secret;
    this.offset_period = offset_period;
    this.project = project;
    this.used_by = used_by;
    this.user = user;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.bought_at = bought_at;
    this.used_at = used_at;
    this.used = used;
    this.s3_url = s3_url;
    this.carbonflux = this.carbonFluxInstances(carbonflux);
    this.publicKey = publicKey;

  }

  get truncateHashIPFS() {
    return Formatter.truncateString(this.ipfs.hash, 20);
  }

  get truncateHashBlockchain() {
    return Formatter.truncateString(this.blockchain.hash, 20);
  }

  static hasCertificate(nft) {
    return nft.certificate != null;
  }

  carbonFluxInstances(carbonflux) {
    return carbonflux.map((item) => new CarbonFlux(item));
  }
}

export default NFT;
