import React from 'react';
import {
  Container,
  Grid,
  OutlinedInput,
  InputAdornment,
  Button,
} from '@material-ui/core';
import Footer from '../footer/Footer';
import {
  CarbonCreditsSearcherLogic,
  useStyles,
  CarbonCreditsSearcherService,
} from './carbonCreditsSearcher.module';
import SearchIcon from '@material-ui/icons/Search';

const CarbonCreditsSearcher = () => {
  const service = CarbonCreditsSearcherService();

  const {
    values,
    searchValue,
    handleInputChange,
    isButtonEnabled,
    handleSubmit,
  } = CarbonCreditsSearcherLogic(service.getNfts);

  const classes = useStyles({ isButtonEnabled });

  return (
    <>
      <Container maxWidth="md" style={{ marginTop: 100, marginBottom: 20 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item lg={10} md={10} sm={10} xs={8}>
            <OutlinedInput
              className={classes.inputCarbonCredits}
              variant="outlined"
              fullWidth
              placeholder="Buscador de Créditos de Carbono"
              value={searchValue}
              onChange={handleInputChange}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              inputProps={{
                maxLength: 11,
              }}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={4}>
            <Button
              className={classes.buttonCarbonCredits}
              fullWidth
              disabled={!isButtonEnabled}
              onClick={handleSubmit}
            >
              Seleccionar
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </>
  );
};

export default CarbonCreditsSearcher;
