import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttonCarbonCredits: {
    width: '100%',
    fontFamily: 'Montserrat',
    border: 'none',
    borderRadius: '20px',
    fontSize: '16px',
    textTransform: 'capitalize',
    color: 'white !important',
    backgroundColor: (props) =>
      props.isButtonEnabled ? '#2CDD00' : '#b1b1b1', 
    '&:hover': {
      backgroundColor: (props) =>
        props.isButtonEnabled ? '#28C300' : '#b1b1b1', 
    },
  },
  inputCarbonCredits: {
    background: '#FFF',
    borderRadius: 10,
    color: '#333',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.borderSelect.borderFocus,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
          border: `0.1px solid ${theme.palette.borderSelect.border}`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `0.1px solid ${theme.palette.borderSelect.border}`,
    },
  },
}));

export default useStyles;
