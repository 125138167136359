import { useState } from 'react';
import Storage from '../../classes/Storage/Storage';

const CarbonCreditsSearcherLogic = (getNfts) => {
  const [values, setValues] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const validateInput = (value) => {
    const isValidPattern = /^[a-zA-Z0-9]{11}$/.test(value);
    const isValidPublicKeyPatter = /^G[A-Z0-9]{55}$/.test(value);
    return isValidPattern || isValidPublicKeyPatter;
  };

  const handleInputChange = (event) => {
    const value = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
    setSearchValue(value);
    setIsButtonEnabled(validateInput(value));
  };

  const handleSubmit = async () => {
    const id = new Storage('id').getItem();
    if (!id) {
      console.error('no se encontró el id');
      return;
    }
    try {
      const nft = await getNfts(id);
      setValues(nft);
    } catch (error) {
      console.error('error:', error);
    }
  };

  return {
    values,
    searchValue,
    handleInputChange,
    isButtonEnabled,
    handleSubmit,
  };
};

export default CarbonCreditsSearcherLogic;
