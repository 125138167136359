import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import Response from '../../classes/Response/Response';
import CustomError from '../../classes/CustomError/CustomError';
import NFT from '../../classes/NFT/NFT';

const CarbonCreditsSearcherService = () => {
    const getNfts = async (id) => {
        const api = new API(`${URL.URL_SERVER}/nfts/${id}`);
        const http_response = await api.get();
        const has_error = http_response instanceof CustomError;
        if (has_error) {
          return http_response;
        } else {
          const response = new Response(http_response, NFT);
          return response.wasSuccessfull().single();
        }
      };
    
      return {
        getNfts    
      };
}

export default CarbonCreditsSearcherService;
